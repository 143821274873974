<template>
	<v-container fluid>
		<h2 class="text-center">
			{{ $t('auth.accountSetup') }}
		</h2>
		<h4 class="text-center">
			{{ $t('auth.yourFirstStep') }}
		</h4>

		<div v-if="!setupInfo.setup.accountType || !['1', '2'].includes(accountType)">
			<h5 class="text-center text-h5 mb-2">
				{{ $t('setup.chooseAccountType') }}
			</h5>
			<div class="d-flex justify-space-around flex-wrap">
				<v-card flat color="blue darken-1" class="my-1 mx-1" width="300" @click="selectStudent()" rounded="xl">
					<v-img class="ma-auto" contain height="200" :src="require(`@/assets/images/setup/student1.svg`)" />
					<v-card-title>
						<span class="mx-auto">{{ $t('setup.student') }}</span>
					</v-card-title>
					<v-card-text class="text-justify">
						{{ $t('setup.studentExplained') }}
					</v-card-text>
				</v-card>
				<v-card flat color="teal darken-1" class="my-1 mx-1" width="300" @click="selectAlumni()" rounded="xl">
					<v-img class="ma-auto" contain height="200" :src="require(`@/assets/images/setup/alumni2.svg`)" />
					<v-card-title>
						<span class="mx-auto">{{ $t('setup.alumni') }}</span>
					</v-card-title>
					<v-card-text class="text-justify">
						{{ $t('setup.alumniExplained') }}
					</v-card-text>
				</v-card>
			</div>
		</div>

		<v-stepper
			v-if="setupInfo.setup.accountType && ['1', '2'].includes(accountType)"
			v-model="stepperIndex"
			class="mt-3 mx-auto"
			style="max-width: 1000px"
		>
			<v-stepper-header>
				<v-stepper-step :complete="stepperIndex > 1" step="1">
					{{ $t('studies.studies') }}
				</v-stepper-step>

				<v-divider />

				<v-stepper-step :complete="stepperIndex > 2" step="2" v-if="!accountAlumni">
					{{ $t('gdrive.gDriveAccounts') }}
				</v-stepper-step>

				<v-divider />

				<v-stepper-step step="3">
					{{ $t('settings.profile') }}
				</v-stepper-step>
			</v-stepper-header>

			<v-stepper-items>
				<v-stepper-content step="1">
					<v-card flat color="transparent" rounded="xl">
						<v-card-title>{{ $t('studies.studies') }}</v-card-title>
						<v-card-text>
							<template v-if="setupInfo.setup.studies">
								<p class="text-body-1 text-justify" style="max-width: 95%">
									{{ $t('studies.setupDoneHelp1') }}
								</p>
							</template>
							<template v-else>
								<Degrees :studies="studies" :student-emails="studentEmails" />
							</template>
						</v-card-text>

						<v-card-actions>
							<v-btn color="primary" @click="validateStep1()">
								{{ $t('search.continue') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>

				<v-stepper-content step="2" v-if="!accountAlumni">
					<v-card flat color="transparent" rounded="xl">
						<v-card-title>{{ $t('gdrive.gDriveAccounts') }}</v-card-title>
						<v-card-text>
							<p>{{ $t('gdrive.gDriveAccountHelp1') }}</p>
							<SettingsAddGoogleDrive :g-drive-emails="gDriveEmails" :max-g-drive-emails="currentUser.limits.gDriveEmails" :flat="true" />
						</v-card-text>

						<v-card-actions>
							<v-btn color="primary" @click="validateStep2()">
								{{ $t('search.continue') }}
							</v-btn>

							<v-btn text @click="stepperIndex--">
								{{ $t('search.back') }}
							</v-btn>

							<v-spacer />

							<v-btn text @click="callDoNotAskAgain()">
								{{ $t('gdrive.skipAndDoNotAskAgain') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>

				<v-stepper-content step="3">
					<v-card flat color="transparent" rounded="xl">
						<v-card-text>
							<v-card-title>{{ $t('settings.profile') }}</v-card-title>
							<p>{{ $t('profile.setupConfHelp1') }}</p>
							<p>{{ $t('profile.setupConfHelp2') }}</p>
							<p>{{ $t('profile.setupConfHelp3') }}</p>
							<v-row key="profileFirstRow">
								<v-col cols="12" md="2" sm="3" xs="5">
									<UserPicture :loading-parent="loading" />
								</v-col>
								<v-col cols="12" md="10" sm="9" xs="7">
									<UserInfo :loading-parent="loading" />
								</v-col>
								<v-col cols="12" lg="7" sm="6" xs="12" class="d-flex flex-column">
									<UserDescription :loading-parent="loading" />
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" lg="5" sm="6" xs="12" class="d-flex flex-column">
									<UserInterests :loading-parent="loading" />
								</v-col>
								<v-col cols="12" lg="7" sm="6" xs="12" class="d-flex flex-column">
									<CVSkills :loading-parent="loading" />
								</v-col>
								<v-col cols="12" lg="5" sm="6" xs="12" class="d-flex flex-column">
									<CVLanguages :loading-parent="loading" />
								</v-col>
								<v-col cols="12" lg="7" sm="6" xs="12" class="d-flex flex-column">
									<CVProjects :loading-parent="loading" />
								</v-col>
							</v-row>
						</v-card-text>

						<v-card-actions>
							<v-btn color="primary" :to="{ name: 'User' }">
								{{ $t('search.continue') }}
							</v-btn>

							<v-btn text @click="step3GoBack()">
								{{ $t('search.back') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('auth.accountSetup'),
		}
	},
	data() {
		return {
			loading: false,
			stepperIndex: 1,
			accountType: '',
		}
	},
	components: {
		Degrees: () => import('@/components/studies/Degrees.vue'),
		SettingsAddGoogleDrive: () => import('@/components/settings/SettingsAddGoogleDrive.vue'),
		UserPicture: () => import('@/components/users/UserPicture.vue'),
		UserInfo: () => import('@/components/users/UserInfo.vue'),
		UserDescription: () => import('@/components/users/UserDescription.vue'),
		UserInterests: () => import('@/components/users/UserInterests.vue'),
		CVSkills: () => import('@/components/cv/CVSkills.vue'),
		CVLanguages: () => import('@/components/cv/CVLanguages.vue'),
		CVProjects: () => import('@/components/cv/CVProjects.vue'),
	},
	computed: {
		accountStudent() {
			return this.accountType == '1'
		},
		accountAlumni() {
			return this.accountType == '2'
		},
		...mapGetters({
			currentUser: 'user/currentUser',
			setupInfo: 'user/setupInfo',
			gDriveEmails: 'gdriveemails/gDriveEmails',
			studies: 'studies/studies',
			studentEmails: 'studies/studentEmails',
		}),
	},
	created() {
		this.open()
		// Make sure the user is logged in or not by refreshing the token
		this.refreshAccess()
			.then(() => {
				this.accountType = this.setupInfo.accountType.value
				if (
					this.setupInfo.accountType?.setup &&
					this.setupInfo.setup.gDriveEmail &&
					this.setupInfo.studies.verifiedStudies &&
					!(this.setupInfo.accountType.student.value && this.setupInfo.studies.allAlumni)
				) {
					this.$router.push({ name: 'Newsfeed' })
				} else if (this.setupInfo.setup.accountType && this.setupInfo.accountType?.student.value) {
					this.fetchGDriveEmails()
				}
				if (this.setupInfo.setup.accountType) {
					this.fetchStudies()
					this.fetchStudentEmails({ userID: this.currentUser.id })
				}
				if (this.setupInfo.studies.verifiedStudies) {
					this.validateStep1()
				}
			})
			.then(() => {
				this.close()
			})
	},
	methods: {
		validateStep1() {
			if (this.studies.length) {
				this.stepperIndex++
				if (this.setupInfo.setup.gDriveEmail) {
					this.stepperIndex++
				}
			}
			window.scrollTo(0, 0)
		},
		validateStep2() {
			this.stepperIndex++
			window.scrollTo(0, 0)
		},
		step3GoBack() {
			if (this.accountAlumni) this.stepperIndex = this.stepperIndex - 2
			else this.stepperIndex--
			window.scrollTo(0, 0)
		},
		callDoNotAskAgain() {
			this.doNotAskAgain()
			this.validateStep2()
		},
		selectStudent() {
			this.accountType = '1'
			this.changeAccountType({ accountType: this.accountType })
		},
		selectAlumni() {
			this.accountType = '2'
			this.changeAccountType({ accountType: this.accountType })
		},
		...mapActions('auth', ['refreshAccess']),
		...mapActions('splash', ['open', 'close']),
		...mapActions('gdriveemails', ['fetchGDriveEmails', 'doNotAskAgain']),
		...mapActions('studies', ['fetchStudies', 'fetchStudentEmails']),
		...mapActions('settings', ['changeAccountType']),
	},
}
</script>
